//
// Main
//

// General mode
.app-main {
  display: flex;
}
.compact p { display: inline }
p { min-height: 10px; }
.correct { background-color: lightgreen !important }
.imgSvg { max-width: 300px }
@keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}
table:not(.table) {
  border-collapse: collapse;
  // width: 100%; /* Optional: set table width to full */
}

table:not(.table) td {
  padding-right: 8px;
  padding-left: 8px; 
  border: 1px solid black; /* Add thin black border */
}
table:not(.table) th {
  padding-right: 8px;
  padding-left: 8px; 
  border: 1px solid black; /* Add thin black border */
}
.btn-nta {
  font-weight: 700;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.42857143;
  border-radius: 0;
}
.btn-success-nta {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-default-nta {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-warning-nta {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-primary-nta {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
svg.a9s-annotationlayer .a9s-selection .a9s-outer, 
svg.a9s-annotationlayer .a9s-annotation .a9s-outer {
  opacity: 0;
}

svg.a9s-annotationlayer .a9s-handle .a9s-handle-outer {
  display:none;
}

/** New style for the annotation outlines **/
svg.a9s-annotationlayer .a9s-selection .a9s-inner,
svg.a9s-annotationlayer .selected .a9s-inner  {
  stroke-width:6;
  stroke:$success;
  /*
  stroke-width:6;
  stroke:$primary;
  stroke-dasharray:8;
  animation: dash 5s linear infinite;
  */
}
svg.a9s-annotationlayer .a9s-selection .a9s-inner,
svg.a9s-annotationlayer .a9s-annotation:not(.selected):not(.annotate-error-select) .a9s-inner  {
  stroke-width:2;
  stroke:$warning;
}
svg.a9s-annotationlayer .annotate-error-select .a9s-inner  {
  stroke-width:6;
  stroke:$danger;
}

/** Disable the hover effect from the default stylesheet **/
svg.a9s-annotationlayer .a9s-annotation.editable:hover .a9s-inner {
  fill:transparent;
}

/** Corner handles **/
svg.a9s-annotationlayer .a9s-handle .a9s-handle-inner {
  fill:white;
  stroke:white;
}

/** Enable the dim mask, black with 60% transparency *
svg.a9s-annotationlayer .selected,
svg.a9s-annotationlayer .a9s-selection-mask {
  fill:rgba(0, 0, 0, 0.6);
}
*/
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}
img {
  max-width: 100%;
  height: auto; /* Ensures that the aspect ratio is maintained */
}
// Desktop mode
@include media-breakpoint-up(lg) {
  // Base
  .app-main {
    transition: $app-main-transition;

    @include property(padding-left, $app-main-padding-x);
    @include property(padding-right, $app-main-padding-x);
    @include property(padding-top, $app-main-padding-y);
    @include property(padding-bottom, $app-main-padding-y);

    @include property(margin-left, $app-main-margin-x);
    @include property(margin-right, $app-main-margin-x);
    @include property(margin-top, $app-main-margin-y);
    @include property(margin-bottom, $app-main-margin-y);
  }

  // Integration
  .app-main {
    // Sidebar
    [data-kt-app-sidebar-sticky='true'] & {
      margin-left: calc(
        var(--#{$prefix}app-sidebar-width) + var(--#{$prefix}app-sidebar-gap-start, 0px) +
          var(--#{$prefix}app-sidebar-gap-end, 0px)
      );
    }

    // Sidebar Panel
    [data-kt-app-sidebar-panel-sticky='true'] & {
      margin-left: calc(
        var(--#{$prefix}app-sidebar-width) + var(--#{$prefix}app-sidebar-gap-start, 0px) +
          var(--#{$prefix}app-sidebar-gap-end, 0px) + var(--#{$prefix}app-sidebar-panel-width) +
          var(--#{$prefix}app-sidebar-panel-gap-start, 0px) +
          var(--#{$prefix}app-sidebar-panel-gap-end, 0px)
      );
    }

    // Aside
    [data-kt-app-aside-sticky='true'] & {
      margin-right: calc(
        var(--#{$prefix}app-aside-width) + var(--#{$prefix}app-aside-gap-start, 0px) +
          var(--#{$prefix}app-aside-gap-end, 0px)
      );
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  // Base
  .app-main {
    @include property(padding-left, $app-main-padding-x-mobile);
    @include property(padding-right, $app-main-padding-x-mobile);
    @include property(padding-top, $app-main-padding-y-mobile);
    @include property(padding-bottom, $app-main-padding-y-mobile);

    @include property(margin-left, $app-main-margin-x-mobile);
    @include property(margin-right, $app-main-margin-x-mobile);
    @include property(margin-top, $app-main-margin-y-mobile);
    @include property(margin-bottom, $app-main-margin-y-mobile);
  }
}
